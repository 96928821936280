/**
 * This module is to allow "Datasak", an old app that are hard to update,
 * to continue using amedia-userdata-browser.
 * Once Datasak is updated this module should be deleted.
 * Method need to send event with this data https://github.com/amedia/datasak/blob/master/www/smartembed/common/DatasakUserData.js#L21
 */

import { UserDataRequest } from '@amedia/user';

window.addEventListener('userdata.data', (e) => {
  const payload = e.detail || {};
  const { method = '', resolve = () => {}, reject = () => {} } = payload;
  if (method !== 'getUserData') {
    return;
  }
  if (payload.handled) {
    return;
  }
  payload.handled = true;
  new UserDataRequest()
    .withContext('ego')
    .withAttributes(['extraData', 'privacyPreferences', 'uuid'])
    .fetch()
    .then((data) => {
      const {
        extraData = {},
        privacyPreferences = {},
        uuid = '',
      } = data.attributes;
      const legacyData = {
        uuid,
        ...extraData,
        privacy_preferences: privacyPreferences,
      };
      resolve(legacyData);
    })
    .catch((e) => reject(e));
});

window.addEventListener('userdata.ready', () =>
  window.dispatchEvent(new CustomEvent('userdata.live', { detail: '3.7.19' }))
);
window.dispatchEvent(new CustomEvent('userdata.live', { detail: '3.7.19' }));
